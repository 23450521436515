<template>
  <HaBaseButton
    class="hm-favorite"
    :class="{
      '-active': isLiked,
      '-base-white': baseWhite,
      '-no-click': noClick,
    }"
    @click.prevent="onClick"
  >
    <IconGood class="icon" />
    <span class="number">{{ displayCount }}</span>
  </HaBaseButton>
</template>

<script setup lang="ts">
import IconGood from '@/assets/icons/icon_good_on.svg'
import { convertNumberToPlus } from '@/utils/math'

type Props = {
  category?: 'like'
  isLiked?: boolean
  count: number
  threshold?: number
  baseWhite?: boolean
  noClick?: boolean
}

const props = defineProps<Props>()

const categoryClass = computed(() => {
  if (!props.category) return ''
  return `-category-${props.category}`
})

const displayCount = computed(() =>
  props.threshold
    ? convertNumberToPlus(props.count, props.threshold)
    : props.count
)

type Emits = {
  (e: 'click', v: boolean): void
}

const emit = defineEmits<Emits>()

const onClick = () => {
  if (props?.noClick) return
  emit('click', !props.isLiked)
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.hm-favorite {
  align-items: center;
  border-radius: 3px;
  color: v.$white;
  display: flex;
  gap: v.space(0.5);
  height: 26px;
  padding: 1px v.space(1);

  @include m.sp() {
    height: 18px;
  }

  > .icon {
    fill: v.$white;
    height: 16px;
    width: 16px;
    @include m.sp() {
      height: 12px;
      width: 12px;
    }
  }

  > .number {
    @include m.sp() {
      font-size: 12px;
    }
  }

  &:not(.-no-click):hover,
  &.-active {
    background-color: v.$pink-1;
    border-color: v.$pink-1;
  }

  &.-base-white {
    border: 1px solid v.$text-note;
    color: v.$text-note;
    height: 32px;
    padding: 1px v.space(2);

    > .icon {
      fill: v.$text-note;
      height: 10px;
      width: 10px;
    }

    &:not(.-no-click):hover,
    &.-active {
      background-color: v.$pink-1;
      border-color: v.$pink-1;
      color: v.$white;

      .icon {
        fill: v.$white;
      }
    }
  }

  &.-no-click {
    cursor: default;
  }
}
</style>
